import React, { useState, useEffect } from 'react';
import './ContactForm.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ message: '', type: '' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email).toLowerCase());
  const validateName = (name) => /^[A-Za-z\s]+$/.test(name);
  const validateSubject = (subject) => subject.length > 0 && subject.length <= 30 && /^[A-Za-z0-9\s]+$/.test(subject);
  const validateMessage = (message) => message.length > 0 && message.length <= 500 && /^[A-Za-z0-9\s]+$/.test(message);

  const handleInputChange = (setter, validator, errorKey) => (e) => {
    const value = e.target.value;
    setter(value);

    const newErrors = { ...errors };
    if (!validator(value)) {
      newErrors[errorKey] = `${errorKey.charAt(0).toUpperCase() + errorKey.slice(1)} is invalid.`;
    } else {
      delete newErrors[errorKey];
    }
    setErrors(newErrors);
  };

  const isFormValid = () => (
    name && validateName(name) &&
    email && validateEmail(email) &&
    subject && validateSubject(subject) &&
    message && validateMessage(message) &&
    Object.keys(errors).length === 0
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!name || !validateName(name)) validationErrors.name = 'Name is required and cannot contain numbers or special characters.';
    if (!email || !validateEmail(email)) validationErrors.email = 'Email is required and must be valid.';
    if (!subject) validationErrors.subject = 'Subject is required.';
    if (!message) validationErrors.message = 'Message is required.';

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await fetch('https://musclemeet.app/api/web/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, subject, message }),
      });

      if (response.ok) {
        setAlert({ message: 'Email sent successfully!', type: 'success' });
        setName('');
        setEmail('');
        setSubject('');
        setMessage('');
        setErrors({});
      } else {
        setAlert({ message: 'Failed to send email. Please try again.', type: 'error' });
      }
    } catch (error) {
      console.error('Error:', error);
      setAlert({ message: 'An error occurred while sending the email.', type: 'error' });
    }
  };

  const closeAlert = () => {
    setAlert({ message: '', type: '' });
  };

  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      <div className="contact-form-container">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              value={name}
              onChange={handleInputChange(setName, validateName, 'name')}
              placeholder="Your name*"
              maxLength="20"
              required
            />
            <span className="char-count">{name.length}/20</span>
            {errors.name && <span className="error-message">{errors.name}</span>}
          </div>
          <div className="form-group">
            <input
              type="email"
              value={email}
              onChange={handleInputChange(setEmail, validateEmail, 'email')}
              placeholder="Your email*"
              required
            />
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>
          <div className="form-group">
            <input
              type="text"
              value={subject}
              onChange={handleInputChange(setSubject, validateSubject, 'subject')}
              placeholder="Your subject*"
              maxLength="30"
              required
            />
            <span className="char-count">{subject.length}/30</span>
            {errors.subject && <span className="error-message">{errors.subject}</span>}
          </div>
          <div className="form-group">
            <textarea
              value={message}
              onChange={handleInputChange(setMessage, validateMessage, 'message')}
              placeholder="Your message*"
              maxLength="500"
              required
            ></textarea>
            <span className="char-count">{message.length}/500</span>
            {errors.message && <span className="error-message">{errors.message}</span>}
          </div>
          <button 
            type="submit" 
            className="submit-button" 
            disabled={!isFormValid()}
          >
            SEND
          </button>
        </form>
      </div>

      {alert.message && (
        <div className={`alert ${alert.type}`}>
          <span>{alert.message}</span>
          <button onClick={closeAlert} className="close-alert">X</button>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
