import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './FAQ.css';

const questions = [
  {
    title: 'Getting Started with Muscle Meet',
    content: [
      {
        question: 'What is Muscle Meet?',
        answer: 'Muscle Meet is a cross-platform mobile app designed to help you log workouts, access exercise instructions, track your fitness progress, and more. Whether you\'re a beginner or a seasoned athlete, Muscle Meet makes it easy to stay on top of your fitness goals.'
      },
      {
        question: 'Which platforms is Muscle Meet available on?',
        answer: 'Muscle Meet is available on both Android and iOS, so you can use it on your smartphone or tablet, regardless of your preferred operating system.'
      },
      {
        question: 'Is Muscle Meet suitable for beginners?',
        answer: 'Definitely! Muscle Meet is designed to be user-friendly and provides exercise instructions that cater to all fitness levels, from beginners to advanced users.'
      }
    ]
  },
  {
    title: 'Language and Customization Options',
    content: [
      {
        question: 'How can I switch between languages in the app?',
        answer: 'Muscle Meet supports multiple languages, including Arabic, English, and French. You can switch languages at any time by going to the app\'s settings and selecting your preferred language.'
      },
      {
        question: 'Does Muscle Meet offer a dark mode?',
        answer: 'Yes, Muscle Meet offers both dark and light modes. You can switch between them based on your preference or to reduce eye strain during night-time use.'
      },
      {
        question: 'How do I customize my workout routines?',
        answer: 'Muscle Meet allows you to create and customize your own workout routines. You can choose from a variety of exercises and tailor your workouts to match your fitness goals.'
      }
    ]
  },
  {
    title: 'Logging Workouts and Tracking Progress',
    content: [
      {
        question: 'Can I import my old workout logs?',
        answer: 'Yes, Muscle Meet allows you to import workout logs from previous days to the current day. This feature ensures that you can update and backtrack your workout history, making it easier to keep your logs accurate and up-to-date.'
      },
      {
        question: 'How do I track my progress over time?',
        answer: 'Muscle Meet allows you to track your progress by logging your workouts and measuring your improvements over time. You can view detailed statistics and charts to stay motivated.'
      },
      {
        question: 'Do I need an internet connection to log my workouts?',
        answer: 'While some features, such as syncing data across devices, require an internet connection, you can log your workouts and access exercise instructions offline.'
      }
    ]
  },
  {
    title: 'Exercise Instructions and Guidance',
    content: [
      {
        question: 'Does Muscle Meet offer exercise instructions?',
        answer: 'Absolutely! Muscle Meet provides detailed instructions and demonstrations for a wide range of exercises, helping you perform them correctly and safely.'
      },
      {
        question: 'Can I create custom workouts using Muscle Meet\'s exercise library?',
        answer: 'Yes, you can select exercises from the extensive library and create custom workouts that fit your fitness goals, all with detailed guidance for proper form.'
      }
    ]
  },
  {
    title: 'Technical Support and Account Management',
    content: [
      {
        question: 'Can I sync Muscle Meet across multiple devices?',
        answer: 'Yes, Muscle Meet supports syncing across multiple devices, ensuring that your workout data is always up-to-date, whether you\'re using a smartphone or tablet.'
      },
      {
        question: 'How do I get help if I have issues with the app?',
        answer: 'If you encounter any issues or have questions about Muscle Meet, you can reach out to our support team via the in-app help section or by contacting us directly through the support email provided.'
      }
    ]
  }
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const contentRefs = useRef([]);
  const navigate = useNavigate();

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
    contentRefs.current[index].style.height = activeIndex === index ? '0px' : `${contentRefs.current[index].scrollHeight}px`;
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);
  return (
    <div className="faq-page">
      <h1>Help and Support</h1>
      {questions.map((category, categoryIndex) => (
        <div key={categoryIndex} className="faq-category">
          <h2 className="faq-category-title">{category.title}</h2>
          <div className="faq-container">
            {category.content.map((item, itemIndex) => (
              <div
                key={itemIndex}
                className={`faq-item ${activeIndex === categoryIndex * 10 + itemIndex ? 'active' : ''}`}
                onClick={() => toggleAccordion(categoryIndex * 10 + itemIndex)}
              >
                <div className="faq-question">
                  {item.question}
                  <span className={`arrow ${activeIndex === categoryIndex * 10 + itemIndex ? 'up' : 'down'}`}></span>
                </div>
                <div
                  ref={(el) => (contentRefs.current[categoryIndex * 10 + itemIndex] = el)}
                  className="faq-answer"
                  style={{
                    height: '0px',
                    overflow: 'hidden',
                    transition: 'height 0.3s ease-in-out',
                  }}
                >
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}

      <div className="contact-section">
        <p>Need more answers ? Reach out and we're all ears !</p>
        <button className="contact-button" onClick={() => navigate('/contact')}>
          Contact Us
        </button>
      </div>
    </div>
  );
};

export default FAQ;